<template>
    <q-layout  view="hHh lpR fff"> <!-- :theme="store.theme" -->
        <HeaderMain dark />
        <q-page-container fluid>
            <loader v-if="store.loader" />
            <q-page>
                <slot />
            </q-page>
        </q-page-container>
        <FooterMain />
    </q-layout>
</template>
<script setup lang="ts">
import { useStore } from '@/store/store';
const store = useStore();     

</script>
<style scoped>
.q-page-container {
    background: #000;
}
</style>
